import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DPS from '../views/dps/dps.index.vue';
import Individual from '../views/cnpj/Individual.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/dps',
    name: 'DPS',
    component: DPS,
  },
  {
    path: '/dps/:cnpj',
    name: 'cnpj',
    component: Individual,
  },
  {
    path: '*',
    redirect: '/dps',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
