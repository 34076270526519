















































































































/* tslint:disable */
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { insurers } from "@/utils/dps.utils";
import { Auth } from "aws-amplify";
import { cnpj } from "cpf-cnpj-validator";

@Component({
  components: {}
})
export default class App extends Vue {
  user: any;

  authState: any;

  cnpj = "";

  insurers = insurers;

  insurer = "";

  cnpjHash = "";

  link = "";

  createDpsDialog = false;

  TEMPLATE_ID = process.env.VUE_APP_TEMPLATE_ID;

  rules = {
    required: (value: any) => !!value || "Obrigatório.",
    cnpj: (value: any) => !!cnpj.isValid(value) || "CNPJ inválido."
  };

  valid = {
    is: (form: any) => form && form.valid
  };

  computed = {
    insurers: () => insurers
  };

  async created() {
    this.cnpjHash = document.location.hash;
    if (!this.cnpjHash) {
      try {
        const authData = await Auth.currentAuthenticatedUser();

        this.user = {
          username: authData.username,
          attributes: authData.attributes
        };

        Vue.prototype.$user = this.user;
      } catch (e) {
        if (e === "The user is not authenticated") {
          if (this.$route.name !== "Home") {
            this.$router.push("/");
          }
        }
      }
    }
  }

  alert = () => this.$store.state.alert;

  loading = () => this.$store.state.loading;

  resetDps() {
    this.link = "";
  }

  async gerarLink() {
    const session = await Auth.currentSession();
    const { email } = session.getIdToken().payload;

    this.link = `${window.location.origin}/?#c=${this.cnpj}&insurer=${this.insurer}&vendor=${email}`;
    this.cnpj = "";
  }

  // eslint-disable-next-line
  copiar() {
    /* Get the text field */
    const copyText = document.getElementById("link") as HTMLInputElement;

    /* Select the text field */
    if (copyText) {
      copyText.select();

      /* Mobile */
      copyText.setSelectionRange(0, 99999);
    }

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  saveUser(authData: any) {
    const groups = authData.signInUserSession.accessToken.payload["cognito:groups"];
    const isHealthTeam = groups ? groups.indexOf("Vitta-Equipe-de-Saude") !== -1 : false;
    const isOnboardingTeam = groups ? groups.indexOf("Vitta-Implantacao") !== -1 : false;
    const canEditProposal = groups ? groups.indexOf("Vitta-Editar-Proposta") !== -1 : false;
    const isComercialTeam = groups ? groups.indexOf("Vitta-Comercial") !== -1 : false;
    const user = {
      ...authData.attributes,
      accessToken: authData.signInUserSession.accessToken,
      isHealthTeam,
      isOnboardingTeam,
      canEditProposal,
      isComercialTeam
    };
    this.$store.commit("saveUser", user);
  }

  authStateChanged(authState: any, authData: any) {
    this.authState = authState;
    switch (authState) {
      case "signin":
        if (this.$route.name !== "Home") {
          this.$router.push("/");
        }

        break;
      case "signedin":
        this.saveUser(authData);
        this.user = {
          username: authData.username,
          attributes: authData.attributes
        };

        Vue.prototype.$user = this.user;
        if (this.$route.name === "Home") {
          this.$router.push("/dps");
        }
        break;

      default: {
        break;
      }
    }
  }
}
