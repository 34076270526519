/* eslint-disable */
export const headers = ({ showFull, showImmediateMember, showStatus }) => {
  const defaultHeader = [
    {
      text: 'CNPJ', value: 'cnpj',
    },
    {
      text: "Identificação",
      value: "name",
      sortable: false,
      align: "start",
    },
  ];

  if (showFull) defaultHeader.push({
    text: 'Sexo',
    value: 'sex',
    sortable: false,
    align: 'start',
  })

  defaultHeader.push({
    text: "Contato",
    value: "email",
    sortable: false,
    align: "start"
  })

  if (showFull) defaultHeader.push({
    text: 'Data de Nascimento',
    value: 'birthDate',
    sortable: false,
    align: 'start',
  },
    {
      text: 'Data do Preenchimento',
      value: 'createdAt',
      align: 'start',
    })

  defaultHeader.push({
    text: "Seguradora",
    value: "insurer",
    align: "start"
  }, {
    text: "Data da Assinatura",
    value: "signedDate",
    align: "start"
  },
    {
      text: "Status",
      value: "step",
      sortable: false,
      align: "start"
    })

  if (showStatus) defaultHeader.push({
    text: "Resultado do preenchimento",
    value: "status",
    sortable: false,
    align: "start"
  })

  if (showImmediateMember) defaultHeader.push({
    text: "Membro Imediato",
    value: "immediateMember",
    sortable: false,
    align: "start"
  })

  defaultHeader.push({
    text: "Ação",
    value: "action",
    sortable: false,
    align: "start"
  })

  return defaultHeader;
}

export const steps = ['Comercial', 'Em Análise Técnica', 'Entrevista Médica', 'Recusado', 'Aprovado']

export const insurers = [
  { text: 'Unimed', value: 'unimed' },
  { text: 'Porto Seguro', value: 'portoseguro' },
  { text: 'Outros', value: 'outros' }
]

export const emailTypes = [
  { text: "Enviar para Time Técnico", value: "technical" },
  { text: "Enviar para Time Primecare", value: "primecare" },
  { text: "Enviar CPTs", value: "cpt" },
];
