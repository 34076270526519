<template>
  <div class="container">
    <v-card>
      <v-card-title>CNPJ: {{ cnpj }}</v-card-title>
      <DataTable :beneficiaries="beneficiaries" :showMoreDatails="showMoreDatails" />
    </v-card>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { API } from "aws-amplify";
import { listDpss } from "@/graphql/queries";
import { mask } from "@/utils/mask";

export default {
  name: "Individual",
  components: {
    DataTable: () => import("@/components/DataTable/DataTable.vue")
  },
  data() {
    return {
      beneficiaries: [],
      showMoreDatails: false
    };
  },
  computed: {
    cnpj() {
      return mask(this.$route.params.cnpj, "##.###.###/####-##");
    }
  },
  methods: {
    async getBeneficiaries() {
      this.$store.commit("loading", true);
      try {
        const beneficiaries = this.$store.getters.beneficiaries({ cnpj: this.cnpj });
        if (beneficiaries) return beneficiaries;

        let token;
        let allItems = [];

        do {
          const { data } = await API.graphql({
            query: listDpss,
            variables: {
              filter: {
                cnpj: { contains: this.cnpj }
              },
              limit: 9999,
              nextToken: token
            }
          });
          const { items, nextToken } = data.listDpss;
          token = nextToken;
          allItems.push(...items);
        } while (token);

        return allItems;
      } catch (error) {
        this.$store.commit("showAlert", {
          type: "error",
          message: "Não foi possivel carregar os itens da DPS"
        });
      } finally {
        this.$store.commit("loading", false);
      }
    }
  },
  async mounted() {
    this.beneficiaries = await this.getBeneficiaries();
  }
};
</script>
