/* eslint-disable */
import { isEmpty } from 'lodash'
export const mask = (value, pattern) => {

  if (isEmpty(value) || isEmpty(pattern)) return ''

  let i = 0;
  const v = value.toString();

  return pattern.replace(/#/g, () => v[i++] || '')
}
