/* eslint-disable import/order */
import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';

import awsExports from './aws-exports';
import router from './router';
import vuetify from './plugins/vuetify';

import VueTheMask from 'vue-the-mask';

import VueMoment from 'vue-moment';

import store from './store';

Amplify.configure(awsExports);

Vue.config.productionTip = false;

Vue.use(VueMoment);
Vue.use(VueTheMask);
Vue.use(VueAxios, axios);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
