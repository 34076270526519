<template>
  <div class="container">
    <v-card>
      <v-card-title>
        DPS
        <v-btn large icon color="success" @click="reportModal = true" v-if="isHealthTeam">
          <v-icon small> mdi-format-page-break </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <report-modal v-if="reportModal" v-model="reportModal" />
      <DataTable :beneficiaries="dps" :showMoreDatails="showMoreDatails" :search="search" />
    </v-card>
  </div>
</template>
<script>
/* eslint-disable */
import { API } from "aws-amplify";
import { listDpss } from "../../graphql/queries";

export default {
  name: "dps.index",
  components: {
    DataTable: () => import("@/components/DataTable/DataTable.vue"),
    ReportModal: () => import("@/components/Report/Report.vue")
  },
  data() {
    return {
      search: "",
      showMoreDatails: true,
      reportModal: false,
      dps: []
    };
  },
  watch: {
    $route: "getDPS",
    options: {
      handler() {
        this.getDPS();
      },
      deep: true
    }
  },
  async mounted() {
    this.getDPS();
  },
  computed: {
    isHealthTeam() {
      return this.$store.state.user.isHealthTeam;
    }
  },
  methods: {
    async getDPS() {
      this.$store.commit("loading", true);
      try {
        let token;
        let allItems = [];

        do {
          const { data } = await API.graphql({
            query: listDpss,
            variables: {
              limit: 9999,
              nextToken: token
            }
          });
          const { items, nextToken } = data.listDpss;
          token = nextToken;
          allItems.push(...items);
        } while (token);
        this.dps = allItems;
      } catch (error) {
        console.error(error);
        this.$store.commit("showAlert", {
          type: "error",
          message: "Não foi possivel carregar os itens da DPS"
        });
      } finally {
        this.$store.commit("loading", false);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-badge {
  ::v-deep .v-badge__badge {
    box-sizing: border-box !important;
  }
}
</style>
