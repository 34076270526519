/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDps = /* GraphQL */ `
  query GetDps($id: ID!) {
    getDps(id: $id) {
      id
      cnpj
      vendor
      cpf
      sex
      email
      phone
      birthDate
      name
      status
      lastName
      cpt
      step
      immediateMember
      signedDate
      createdAt
      updatedAt
      documentKey
      editedName
      insurer
      acceptedTerm
      motherName
    }
  }
`;
export const listDpss = /* GraphQL */ `
  query ListDpss(
    $filter: ModelDpsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDpss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cnpj
        vendor
        cpf
        sex
        email
        phone
        birthDate
        name
        status
        lastName
        cpt
        step
        immediateMember
        signedDate
        createdAt
        updatedAt
        documentKey
        editedName
        insurer
        acceptedTerm
        motherName
      }
      nextToken
    }
  }
`;
export const getSentEmails = /* GraphQL */ `
  query GetSentEmails($cnpj: String!) {
    getSentEmails(cnpj: $cnpj) {
      cnpj
      insurer
      currentPlan
      totalMonthlyPremium
      closedPlan
      typeSubscription
      startDate
      closerEmail
      createdAt
      updatedAt
    }
  }
`;
export const listSentEmailss = /* GraphQL */ `
  query ListSentEmailss(
    $cnpj: String
    $filter: ModelSentEmailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSentEmailss(
      cnpj: $cnpj
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cnpj
        insurer
        currentPlan
        totalMonthlyPremium
        closedPlan
        typeSubscription
        startDate
        closerEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSentEmailCpt = /* GraphQL */ `
  query GetSentEmailCpt($id: ID!) {
    getSentEmailCPT(id: $id) {
      id
      cnpj
      name
      email
      document
      status
      insurer
      createdAt
      updatedAt
    }
  }
`;
export const listSentEmailCpTs = /* GraphQL */ `
  query ListSentEmailCpTs(
    $filter: ModelSentEmailCPTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentEmailCPTs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cnpj
        name
        email
        document
        status
        insurer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
