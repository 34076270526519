/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import { isEmpty } from 'lodash'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    alert: {
      type: 'success',
      message: 'default message',
      show: false,
    },
    beneficiaries: {},
    user: {}
  },
  mutations: {
    showAlert(state, { type, message }) {
      state.alert.type = type;
      state.alert.message = message;
      state.alert.show = true;
      setTimeout(() => {
        state.alert.show = false;
      }, 3000);
    },
    saveBeneficiaries(state, { cnpj, beneficiaries }) {
      const key = cnpj.replace(/\D/g, '')
      state.beneficiaries = {
        ...state.beneficiaries,
        [key]: beneficiaries,
      }
    },
    loading(state, isLoading = false) {
      state.loading = isLoading
    },
    saveUser(state, user) {
      state.user = user
    }
  },
  getters: {
    beneficiaries: (state) => ({ cnpj }) => {
      if (isEmpty(cnpj)) return
      const key = cnpj.replace(/\D/g, '')
      return state.beneficiaries[key]
    }
  }
});

export default store;
